import React from 'react';
import { Table, Button, Row, Col, Card } from 'react-bootstrap';
import { FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { GiTennisRacket, GiTrophyCup, GiTennisCourt } from 'react-icons/gi';
import '../../styles/Cours_stages/Stages.css';

const StagesTennisPadel = () => {
  const formulas = [
    {
      title: 'TENNIS PADEL',
      description: [
        'Stages de tennis intensifs & padel',
        'Tous niveaux dès 6 ans',
        'Activités complémentaires: mini-foot, baseball, ...',
      ],
      email: 'neupretennischallenge@gmail.com',
      info: 'Epsylon BE51 0018 6884 7062',
      bgColor: '#28a745',
      icon: <GiTennisRacket />,
    },
    {
      title: 'PADEL TENNIS',
      description: [
        'Stages de padel intensifs & tennis',
        'Tous niveaux dès 6 ans',
        'Activités complémentaires: mini-foot, baseball, ...',
      ],
      email: 'neupretennischallenge@gmail.com',
      info: '10 is Padel BE58 3631 4827 8179',
      bgColor: '#28a745',
      icon: <GiTennisCourt />,
    },
    {
      title: 'TENNIS COMPETITION',
      description: [
        'Entraînement et suivi en compétition officielle dès 9 ans',
        'Supplément de 30 €/semaine',
      ],
      email: 'simonarnoldtennis@gmail.com',
      info: 'Epsylon BE51 0018 6884 7062',
      bgColor: '#007bff',
      icon: <GiTrophyCup />,
    },
    {
      title: 'ADULTES',
      description: [
        'En soirée, à la carte exclusivement > groupes constitués par vos soins !',
      ],
      email: 'neupretennischallenge@gmail.com',
      info: 'Epsylon BE51 0018 6884 7062',
      bgColor: '#007bff',
      icon: <GiTennisRacket />,
    },
  ];

  return (
    <>
      <h1 className="text-center my-4">Stages Tennis & Padel</h1>
      <h2 className="text-center my-4">4 formules disponibles</h2>
      <Row className="mb-4">
        {formulas.map((formula, index) => (
          <Col md={6} lg={6} key={index} className="mb-4">
            <Card className="formula-card" style={{ backgroundColor: formula.bgColor }}>
              <Card.Body>
                <div className="formula-icon">{formula.icon}</div>
                <Card.Title className="text-center formula-title">{formula.title}</Card.Title>
                <Card.Text>
                  <ul className="formula-description">
                    {formula.description.map((line, i) => (
                      <li key={i}>{line}</li>
                    ))}
                  </ul>
                </Card.Text>
                <Card.Text className="text-center">
                  <strong>Renseignements & Inscriptions</strong>
                </Card.Text>
                <Card.Text className="text-center">
                  <FaEnvelope /> {formula.email}
                </Card.Text>
                <Card.Text className="text-center">
                  <FaInfoCircle /> {formula.info}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <div className="text-center my-4">
        <Table responsive bordered hover className="stages-table">
          <thead>
            <tr>
              <th>Période</th>
              <th>Tarif</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Semaines de 5 jours ouvrables</td>
              <td>
                <Button variant="danger" className="price-button">135€</Button>
              </td>
            </tr>
            <tr>
              <td>Semaines de 4 jours ouvrables</td>
              <td>
                <Button variant="danger" className="price-button">110€</Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className="information-section">
        <h3>Informations générales</h3>
        <p>Activités de <strong>09:00 à 15:30</strong></p>
        <p>Garderie possible dès <strong>8:20</strong> et jusqu'à <strong>16:30</strong></p>
        <p>Repas chauds de midi proposés <strong>25€</strong> semaines de 4 jours & <strong>30€/semaine</strong> de 5 jours à réserver à l'inscription</p>
        <p>Prêt de matériel</p>
        <p>Attestations mutuelles sur demande</p>
      </div>
      <div className="alert alert-danger text-center mt-4">
        Merci de respecter les modalités spécifiques à chaque formule proposée EN ANNEXE pour infos et paiements. Merci d'indiquer à chaque fois en communication : Nom et prénom du stagiaire, année de naissance, formule et semaine choisie.
      </div>
    </>
  );
};

export default StagesTennisPadel;
