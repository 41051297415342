import React from 'react';
import { Table, Button, Row, Col, Card } from 'react-bootstrap';
import { FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { GiTennisBall } from 'react-icons/gi';
import '../../styles/Cours_stages/Stages.css';

const StagesHappyKids = () => {
  const formulas = [
    {
      title: 'Mini-Tennis (2,5 à 4 ans)',
      description: [
        '2 séances TENNIS chaque jour, mais encore...',
        'Psychomotricité, padel, mini foot, unihockey, mini-basket, bricolages, heure du conte, jeux de coopération',
      ],
      email: 'happykids.epsylon@gmail.com',
      info: 'Epsylon BE51 0018 6884 7062',
      bgColor: '#0056b3',
      icon: <GiTennisBall />,
    },
    {
      title: 'Mini-Tennis (4 à 6 ans)',
      description: [
        '3 séances TENNIS chaque jour, mais encore...',
        'Psychomotricité, padel, mini foot, unihockey, mini-basket, bricolage...',
      ],
      email: 'happykids.epsylon@gmail.com',
      info: 'Epsylon BE51 0018 6884 7062',
      bgColor: '#0056b3',
      icon: <GiTennisBall />,
    },
  ];

  return (
    <>
      <h1 className="text-center my-4">Stages Happy Kids</h1>
      <h2 className="text-center my-4">2 formules disponibles</h2>

      <Row className="mb-4">
        {formulas.map((formula, index) => (
          <Col md={6} lg={6} key={index} className="mb-4">
            <Card className="formula-card" style={{ backgroundColor: formula.bgColor }}>
              <Card.Body>
                <div className="formula-icon">{formula.icon}</div>
                <Card.Title className="text-center formula-title">{formula.title}</Card.Title>
                <Card.Text>
                  <ul className="formula-description">
                    {formula.description.map((line, i) => (
                      <li key={i}>{line}</li>
                    ))}
                  </ul>
                </Card.Text>
                <Card.Text className="text-center">
                  <strong>Renseignements & Inscriptions</strong>
                </Card.Text>
                <Card.Text className="text-center">
                  <FaEnvelope /> {formula.email}
                </Card.Text>
                <Card.Text className="text-center">
                  <FaInfoCircle /> {formula.info}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <div className="text-center my-4">
        <Table responsive bordered hover className="stages-table">
          <thead>
            <tr>
              <th>Période</th>
              <th>Tarif</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Semaines de 5 jours ouvrables</td>
              <td>
                <Button variant="danger" className="price-button">135€</Button>
              </td>
            </tr>
            <tr>
              <td>Semaines de 4 jours ouvrables</td>
              <td>
                <Button variant="danger" className="price-button">110€</Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className="information-section">
        <h3>Informations générales</h3>
        <p>Activités de <strong>09:00 à 15:30</strong></p>
        <p>Garderie possible dès <strong>8:20</strong> et jusqu'à <strong>16:30</strong></p>
        <p>Repas chauds de midi proposés <strong>25€</strong> semaines de 4 jours & <strong>30€/semaine</strong> de 5 jours à réserver à l'inscription</p>
        <p>Prêt de matériel</p>
        <p>Attestations mutuelles sur demande</p>
      </div>
      <div className="alert alert-danger text-center mt-4">
        Merci de respecter les modalités spécifiques à chaque formule proposée EN ANNEXE pour infos et paiements. Merci d'indiquer à chaque fois en communication : Nom et prénom du stagiaire, année de naissance, formule et semaine choisie.
      </div>
    </>
  );
};

export default StagesHappyKids;
